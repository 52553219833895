$background-color: #F5F5F5;
$color: #585858;
 
$content: "\f099";
$name : shares;

@import "generic";

.shares {
    font-size: 13px;
    height:44px;
    box-sizing: border-box;
    .counter {
        position: absolute;
        left:0;
        top:0;
        right:0;
        bottom:0;
        width:100%;
        height:100%;        
        text-align:center;   
        font-family: Open Sans;
        font-size:10px;
        p {
            margin: 0;            
            display: block;   
            height: 100%;             
            line-height: 14px;
            padding-top: 8px;
            &:after {                 
                display:block;
                content: "SHARES";                
                font-size: 10px;
                margin:0;
            }
        }                   
    }
}