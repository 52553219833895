$width: 48px;
$height: 48px;
$background-color: #F5F5F5;
$padding: 2px;
$social-shares-color: #1C1C1C;
$socials-prefix: socialgenius;

.#{$socials-prefix} {
   a.#{$socials-prefix},
   a.#{$socials-prefix}-shares {
       text-decoration: none;
   }
   
}
.#{$socials-prefix}.inline {
    line-height: 0;
    a.#{$socials-prefix},
    a.#{$socials-prefix}-shares {
        display:inline-block;        
    }
    a.#{$socials-prefix}:hover { 
        transform: translate(0,-8px);       
        transition: 0.120s ease-in-out;
    }
}

.#{$socials-prefix}.block {
        
    a.#{$socials-prefix},
    a.#{$socials-prefix}-shares {
        display:block;
    }
    a.#{$socials-prefix}:hover {       
        right:12px;
        transition: right 0.120s ease-in-out;
    }
}

a.#{$socials-prefix} {
    cursor: pointer;   
    width: $width;
    height: $height;
    text-align:center;
    right:0;
}

/*
a.#{$socials-prefix}-shares { 
    cursor: auto;
    font-family: sans-serif;
    font-size: 14px;
    padding: $padding;
    width: $width - 2 * $padding ;
    height: 38px;        
    line-height: 18px;
    text-align: center;
    color: $social-shares-color;
    background-color: $background-color;
    
    label {
        font-size:10px;
        display:inline;
        clear:both;
    }
}
*/
@import "networks/facebook";
@import "networks/linkedin";
@import "networks/twitter";
@import "networks/mail";
@import "networks/google";
@import "networks/whatsapp";
@import "networks/shares";