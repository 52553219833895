$line-height: 48px;

a.#{$name} { 
    position: relative;
    background: $background-color;
    fill: $color;
    color: $color;       
}

svg.socialgenius-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height:40px;
    margin-left: -20px;
    margin-top: -20px;
}
